import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Moment from "moment"

import Layout from "../../components/layout"

import CTABlock from "../../components/blocks/cta_block"

Moment.locale("en")

const NewsDetailPage = ({ data }) => {
  if (!data.wagtail.page) {
    return <></>
  }
  return (
    <Layout parent={"news"}>
      <section className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/news/">News</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-area news-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3>{data.wagtail.page?.categoryName ?? ""}</h3>
              <p className="pt-10">
                {Moment(data.wagtail.page.date).format("MMMM D, YYYY")}
              </p>
              <h1>{data.wagtail.page.title}</h1>
              <img
                className="mt-10 img-fluid"
                src={
                  data.wagtail.page.feedImage
                    ? data.wagtail.page.feedImage.src
                    : ""
                }
                srcSet={
                  data.wagtail.page.feedImage
                    ? data.wagtail.page.feedImage.srcSet
                    : ""
                }
                width="100%"
                height="637px"
                style={{
                  backgroundColor: data.wagtail.page.feedImage
                    ? "none"
                    : "#A7A8AA",
                }}
                alt="News"
              />
              <p className="p-intro">{data.wagtail.page.detail}</p>
              <div
                className="c-title-content mb-80"
                dangerouslySetInnerHTML={{ __html: data.wagtail.page.bodySafe }}
              />
            </div>
          </div>
        </div>
      </section>
      {data.wagtail.page.fullContent &&
        data.wagtail.page.fullContent.length > 0 && (
          <CTABlock data={data.wagtail.page.fullContent[0]} />
        )}
    </Layout>
  )
}

NewsDetailPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ($id: Int) {
    wagtail {
      page(id: $id) {
        ... on NewPage {
          id
          date
          categoryName
          title
          bodySafe
          detail
          feedImage {
            ... on ImageObjectType {
              src
              width
              height
              srcSet(sizes: 1280)
            }
          }
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`

export default NewsDetailPage
